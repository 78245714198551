var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('div',{class:{
    'estimation-left-box': true,
    'selected-item': _vm.item.selected,
  }}),_c('v-layout',{attrs:{"column":""}},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.item.items,"hide-header":_vm.hideHeader},scopedSlots:_vm._u([{key:"printPrice",fn:function(ref){
  var printPrice = ref.item.printPrice;
return [_vm._v(" "+_vm._s(_vm.formatNumber(printPrice, 3))+" ")]}},{key:"weight",fn:function(ref){
  var weight = ref.item.weight;
return [_vm._v(" "+_vm._s(_vm.formatNumber(weight, 3))+" ")]}},{key:"mailing",fn:function(ref){
  var mailing = ref.item.mailing;
return [_vm._v(" "+_vm._s(mailing ? 'Yes' : 'No')+" ")]}},{key:"printProviderId",fn:function(ref){
  var printProviderId = ref.item.printProviderId;
return [_vm._v(" "+_vm._s(_vm.accountNameById[printProviderId])+" ")]}},{key:"error",fn:function(ref){
  var error = ref.item.error;
return [_vm._v(" "+_vm._s(_vm.estimationErrorsByTypeId[error])+" ")]}},{key:"selectedRate",fn:function(ref){
  var selectedRate = ref.item.selectedRate;
return [(selectedRate)?_c('v-layout',{staticClass:"py-1",attrs:{"column":""}},[_c('v-layout',[_c('div',{staticClass:"text--disabled",staticStyle:{"width":"105px"}},[_vm._v(" Service Name: ")]),_vm._v(" "+_vm._s(selectedRate.serviceName)+" ")]),_c('v-layout',[_c('div',{staticClass:"text--disabled",staticStyle:{"width":"105px"}},[_vm._v(" Delivery Date: ")]),_vm._v(" "+_vm._s(_vm.formatDisplayDate(selectedRate.deliveryDate, { type: 'utc' }))+" ")]),_c('v-layout',[_c('div',{staticClass:"text--disabled",staticStyle:{"width":"105px"}},[_vm._v(" Ship Cost: ")]),_vm._v(" "+_vm._s(selectedRate.shipCost)+" ")])],1):_c('span',[_vm._v(" - ")])]}},{key:"rates",fn:function(ref){
  var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!(item.rates || []).length,"icon":"","color":"info"},on:{"click":function($event){return _vm.showRates(item.rates || [])}}},[_c('v-icon',[_vm._v(" remove_red_eye ")])],1)]}}])}),_c('v-layout',{staticClass:"mt-1 pl-4",staticStyle:{"font-size":"12px","padding-right":"12%"},attrs:{"justify-space-between":""}},[_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s((_vm.item.errors || []).map(function (e) { return _vm.estimationErrorsByTypeId[e]; }).join('; '))+" ")]),(_vm.item.shipExpensivePercent)?_c('span',[_c('strong',{staticClass:"mr-1 warning--text"},[_vm._v(" > ")]),_c('span',{staticClass:"warning--text"},[_vm._v(" "+_vm._s(((_vm.item.shipExpensivePercent || 0) + "%"))+"; ")])]):_vm._e()]),_c('v-layout',{attrs:{"align-center":""}},[_c('v-divider'),_c('span',{staticClass:"mx-2 font-italic",staticStyle:{"font-size":"12px","font-weight":"lighter","opacity":"0.5"}},[_vm._v(" end of estimation ")]),_c('v-divider')],1)],1),_c('item-rates',{attrs:{"rates":_vm.modalRates},model:{value:(_vm.ratesDialog),callback:function ($$v) {_vm.ratesDialog=$$v},expression:"ratesDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }