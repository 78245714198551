<template>
  <vbt-dialog
    v-model="dialog"
    hide-submit-btn
    width="80%"
  >
    <vbt-table
      :headers="headers"
      :items="rates"
    >
      <template #shipDate="{ item: { shipDate: _date } }">
        {{ formatDisplayDate(_date, { type: 'utc' }) }}
      </template>

      <template #deliveryDate="{ item: { deliveryDate: _date } }">
        {{ formatDisplayDate(_date, { type: 'utc' }) }}
      </template>

      <template #accepted="{ item: { accepted: _state } }">
        <v-icon :color="_state ? 'success' : 'error'">
          {{ _state ? 'check' : 'block' }}
        </v-icon>
      </template>

      <template #selected="{ item: { selected: _state } }">
        <v-icon :color="_state ? 'success' : 'error'">
          {{ _state ? 'check' : 'block' }}
        </v-icon>
      </template>
    </vbt-table>
  </vbt-dialog>
</template>

<script>
import { formatDisplayDate } from '@helpers';

const headers = Object.freeze([
  { text: 'Ship Date', value: 'shipDate', width: '20%' },
  { text: 'Delivery Date', value: 'deliveryDate', width: '20%' },
  { text: 'Ship Cost', value: 'shipCost', width: '10%' },
  { text: 'Shipper Type', value: 'shipperType', width: '15%' },
  { text: 'Service Code', value: 'serviceCode', width: '15%' },
  { text: 'Service Name', value: 'serviceName', width: '20%' },
  { text: 'Accepted', value: 'accepted', width: '20px' },
  { text: 'Selected', value: 'selected', width: '20px' },
]);

export default {
  name: 'ItemRates',

  model: {
    event: 'input',
    prop: 'value',
  },

  props: {
    rates: {
      type: Array,
      required: true,
    },
    value: Boolean,
  },

  data() {
    return {
      headers,
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },

  methods: {
    formatDisplayDate,
  },
};
</script>
