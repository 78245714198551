var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-dialog',{attrs:{"hide-submit-btn":"","width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.rates},scopedSlots:_vm._u([{key:"shipDate",fn:function(ref){
var _date = ref.item.shipDate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_date, { type: 'utc' }))+" ")]}},{key:"deliveryDate",fn:function(ref){
var _date = ref.item.deliveryDate;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(_date, { type: 'utc' }))+" ")]}},{key:"accepted",fn:function(ref){
var _state = ref.item.accepted;
return [_c('v-icon',{attrs:{"color":_state ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_state ? 'check' : 'block')+" ")])]}},{key:"selected",fn:function(ref){
var _state = ref.item.selected;
return [_c('v-icon',{attrs:{"color":_state ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_state ? 'check' : 'block')+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }