var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-content-box',{attrs:{"loading-error":_vm.loadingError,"loading":_vm.loading,"title":"Information"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('estimation-input-message',{attrs:{"loading-error":_vm.loadingError,"loading":_vm.loading}})]},proxy:true}])},[_c('v-layout',[_c('v-flex',{attrs:{"sm3":""}},[_c('v-layout',{staticClass:"my-2"},[_c('strong',{staticClass:"mr-1"},[_vm._v(" Invoice: ")]),(_vm.estimation.invoice)?_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
            name: 'estimations',
            query: {
              pageNum: '1',
              searchId: _vm.estimation.invoice
            },
          }}},[_vm._v(" "+_vm._s(_vm.estimation.invoice)+" ")]):_c('span',[_vm._v(" - ")])],1),_c('v-layout',{staticClass:"my-2"},[_c('strong',{staticClass:"mr-1"},[_vm._v(" Sender: ")]),_vm._v(" "+_vm._s(_vm.estimation.sender)+" ")]),_c('v-layout',{staticClass:"my-2"},[_c('strong',{staticClass:"mr-1"},[_vm._v(" Errors: ")]),_vm._v(" "+_vm._s((_vm.estimation.errors || []).join('; ') || '-')+" ")]),_c('v-layout',{staticClass:"my-2"},[_c('strong',{staticClass:"mr-1"},[_vm._v(" Search IDs: ")]),_c('ir-estimation-search-ids',{attrs:{"search-ids":_vm.estimation.searchIds || []}})],1)],1),_c('v-flex',{attrs:{"sm4":""}},[_c('v-layout',{staticClass:"my-2"},[_c('strong',{staticClass:"mr-1"},[_vm._v(" Created Date: ")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.estimation.created,'local', '-'))+" ")]),_c('v-layout',{staticClass:"my-2"},[_c('strong',{staticClass:"mr-1"},[_vm._v(" Expire Date: ")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.estimation.expire,'local', '-'))+" ")]),_c('v-layout',{staticClass:"my-2"},[_c('strong',{staticClass:"mr-1"},[_vm._v(" Estimation Time: ")]),_vm._v(" "+_vm._s(_vm.estimation.estimationTime || '-')+" ")])],1),(_vm.estimation.orderId)?_c('v-flex',{attrs:{"sm3":""}},[_c('v-layout',{staticClass:"my-2"},[_c('strong',[_vm._v(" Order ID: ")]),_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
            name: 'order',
            params: { id: _vm.estimation.orderId },
          }}},[_vm._v(" "+_vm._s(_vm.estimation.orderId)+" ")])],1),_c('v-layout',{staticClass:"my-2"},[_c('strong',{staticClass:"mr-1"},[_vm._v(" Shipping Fee: ")]),_vm._v(" "+_vm._s(_vm.estimation.shippingFee)+" ")]),_c('v-layout',{staticClass:"my-2"},[_c('strong',{staticClass:"mr-1"},[_vm._v(" Shipping Expensive Percent: ")]),_vm._v(" "+_vm._s(_vm.estimation.shipExpensivePercent ? ((_vm.estimation.shipExpensivePercent) + "%") : '-')+" ")]),_c('v-layout',{staticClass:"my-2"},[_c('strong',{staticClass:"mr-1"},[_vm._v(" Contract: ")]),_vm._v(" "+_vm._s(_vm.estimation.contract)+" ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }